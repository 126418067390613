import { Button } from "~/components/ui/button";

const OutlineButton = ({ children, variant }) => {
  return (
    <div className="group ">
      {variant == "dark" ? (
        <div className="h-[45px] rounded-md bg-white p-[0.065rem] active:p-[2px] group-hover:bg-gradient-blue-purple md:h-[57px]">
          <Button
            aria-label="link"
            className="h-full w-full rounded-md bg-black p-0 hover:bg-black"
          >
            {children}
          </Button>
        </div>
      ) : (
        <div className="rounded-md bg-black p-[0.065rem] active:bg-rainbow-r2 active:p-[2px] group-hover:bg-gradient-blue-purple">
          <Button
            aria-label="link"
            className="h-full w-full rounded-md bg-white p-0 hover:bg-white"
          >
            {children}
          </Button>
        </div>
      )}
    </div>
  );
};

export default OutlineButton;
