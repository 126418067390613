"use client";
import React, { useRef, useEffect } from "react";
import lottie, { AnimationItem } from "lottie-web";

const LottieIcon = ({ animationData, width, height, start = 0, end = 100 }) => {
  const animationContainer = useRef(null);
  const anim = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if (animationContainer.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData,
      });

      anim.current.playSegments([start, end], true);
    }
    return () => {
      if (anim.current) {
        anim.current.destroy();
      }
    };
  }, [animationData, start, end]);

  const style = {
    height: height,
    width: width,
  };

  return <div ref={animationContainer} style={style} />;
};

export default LottieIcon;
