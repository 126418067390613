"use client";
import React, { useRef, useState } from "react";
import Image from "next/image";
import { ComponentNotFoundInCMS } from "~/components/cms-not-found";
import AspectRatioComponent from "components/aspect-ratio";
import { Button } from "components/ui/button";
import Link from "next/link";
import { type SwiperRef } from "~/types/types";
import ResearchCarousel from "./research-carousel";
import LottieIcon from "~/components/reusables/lottie-icon";
import Research_Black from "data/animation-data/Research_Black.json";
import OutlineButton from "~/components/reusables/outline-button";
import { ArrowLongLeft, ArrowLongRight } from "~/components/icons";

export const Research = ({ research }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef<SwiperRef | null>(null);
  const goNext = () => {
    swiperRef.current?.swiper?.slideNext();
  };

  const goPrev = () => {
    swiperRef.current?.swiper?.slidePrev();
  };
  const handleSlideChange = (activeIndex: number) => {
    setActiveIndex(activeIndex);
  };
  if (research) {
    const [{ attributes }] = research;
    const cards = attributes.ui;
    return (
      <div className="relative my-[80px] md:my-[150px] lg:my-[180px]">
        <div className="absolute left-[-59px] top-[-62px] z-10">
          <div className="w-[67px]">
            <AspectRatioComponent aspectRatio={{ width: 54, height: 62 }}>
              <Image
                src={"/images/pages/home/corner-left.svg"}
                fill
                alt="Corner"
                unoptimized
              />
            </AspectRatioComponent>
          </div>
        </div>
        <div className="absolute top-0 z-0 h-[1px] w-11/12 bg-rainbow-r2"></div>
        <div className="absolute left-0 z-0 h-2/3 w-[1px] bg-rainbow-b2 md:h-full"></div>
        <div className="flex flex-col justify-between md:flex-row">
          <div className="p-8 md:p-12 md:px-16">
            <div className="w-[55px]">
              <LottieIcon
                animationData={Research_Black}
                width={60}
                height={60}
                start={10}
              />
            </div>
            <h1 className="font-h1 my-3 max-w-[300px] md:my-8">
              {attributes.header}
            </h1>
            <h5 className="font-h5 mb-10 max-w-[450px]">
              {attributes.subheader}
            </h5>
            <div className="inline-block">
              <OutlineButton variant={"light"}>
                <Link
                  href="https://research.cisco.com"
                  target="_blank"
                  aria-label="Learn more about Cisco research"
                  className="font-btn flex items-center gap-1 px-5 py-3 text-black "
                >
                  Learn more{" "}
                  <span className="absolute h-[1px] w-[1px] overflow-hidden">
                    about research
                  </span>
                  <div className="ml-3">
                    <ArrowLongRight size={30} />
                  </div>
                </Link>
              </OutlineButton>
            </div>
          </div>
          <div className="">
            <div className="max-w-[400px] self-end rounded-b-lg bg-black text-white lg:max-w-[700px]">
              <ResearchCarousel
                cards={cards}
                swiperRef={swiperRef}
                onSlideChange={handleSlideChange}
              />
            </div>
            <div className="relative z-[2] mt-6 flex flex-row-reverse items-center justify-center gap-1 md:flex-row">
              <div className="flex gap-1">
                <Button
                  onClick={goPrev}
                  variant="ghost"
                  className="p-0"
                  aria-label="Prev"
                  disabled={activeIndex === 0}
                >
                  <ArrowLongLeft size={55} />
                </Button>
                <Button
                  onClick={goNext}
                  variant="ghost"
                  className="p-0"
                  aria-label="Next"
                  disabled={activeIndex === cards.length - 1}
                >
                  <ArrowLongRight size={55} />
                </Button>
              </div>
              <p className="font-h5 ml-4">
                0{activeIndex + 1} -{" "}
                <span className=" text-slate-500">0{cards.length}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <ComponentNotFoundInCMS message="Section could not be loaded" />;
  }
};
