"use client";
import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import AspectRatioComponent from "components/aspect-ratio";
import Image from "next/image";

const images = [
  { src: "/images/pages/home/hero-svg-1.svg", height: 660 },
  { src: "/images/pages/home/hero-svg-2.svg", height: 580 },
  { src: "/images/pages/home/hero-svg-3.svg", height: 580 },
];

const BGAnimation = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        key={index}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        className="container absolute right-[-5%] top-[300px] w-[300px] md:right-[0%] md:top-[180px] md:w-[400px] lg:right-[5.5%] lg:w-[655px] lg:top-[168px]"
      >
        <AspectRatioComponent
          aspectRatio={{ width: 580, height: images[index]?.height }}
        >
          <Image unoptimized src={images[index]?.src || ''} fill alt="BG" className="z-0" />
        </AspectRatioComponent>
      </motion.div>
    </AnimatePresence>
  );
};

export default BGAnimation;
