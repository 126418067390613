"use client";
import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import Image from "next/image";
import AspectRatioComponent from "~/components/aspect-ratio";
import Link from "next/link";
import { ArrowUpRight } from "~/components/icons";

type SwiperRef = {
  swiper: SwiperType;
};
type Props = {
  cards: any[];
  swiperRef: React.MutableRefObject<SwiperRef | null>;
  onSlideChange?: (activeIndex: number) => void;
};
const ResearchCarousel = ({ cards, swiperRef, onSlideChange }: Props) => {
  useEffect(() => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;

      const handleSlideChange = () => {
        onSlideChange?.(swiper.activeIndex);
      };

      swiper.on("slideChange", handleSlideChange);

      return () => {
        swiper.off("slideChange", handleSlideChange);
      };
    }
  }, [swiperRef, onSlideChange]);
  return (
    // @ts-ignore
    <Swiper className="mySwiper max-w-full" slidesPerView={1} ref={swiperRef}>
      {cards.map((card) => {
        return (
          <SwiperSlide key={card.id} className="">
            <Link
              href={card.button.url}
              target={card.button.target}
              className="group w-full"
            >
              <div className="w-full overflow-hidden">
                <AspectRatioComponent
                  aspectRatio={{
                    width: card?.background.data?.attributes.width,
                    height: card?.background.data?.attributes.height,
                  }}
                >
                  <Image
                    src={card.background.data?.attributes.url}
                    fill
                    unoptimized
                    alt={card.header}
                    className="transition-all duration-700 group-hover:scale-105"
                  />
                </AspectRatioComponent>
              </div>
              <div className="p-6 md:p-12">
                <div className="flex items-center justify-between">
                  <div className="w-9/12 md:w-7/12">
                    {card.header == "Quantum Lab" ? (
                      <div className="w-full md:w-[259px]">
                        <AspectRatioComponent
                          aspectRatio={{
                            width: card?.logo.data?.attributes.width,
                            height: card?.logo.data?.attributes.height,
                          }}
                        >
                          <Image
                            src={card?.logo.data.attributes.url}
                            fill
                            unoptimized
                            alt={card.header}
                          />
                        </AspectRatioComponent>
                      </div>
                    ) : (
                      <div className="flex min-h-[54px] items-center font-light">
                        <h5 className="text-2xl text-[#01BCEA]">
                          {card.header}
                        </h5>
                      </div>
                    )}
                  </div>
                  <div className="hidden lg:block">
                    <div className="flex items-center justify-center gap-3 uppercase">
                      <h6 className="font-thin uppercase group-hover:underline group-hover:underline-offset-2">
                        {card.button.cta}
                      </h6>
                      <div>
                        <div className="block rounded-md border border-white">
                          <ArrowUpRight size={30} color="white" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="font-p my-5">{card.description}</p>
                <div className="mt-6 lg:hidden">
                  <div className="flex items-center justify-end gap-3 uppercase">
                    <h6 className="text-sm font-thin uppercase hover:underline">
                      {card.button.cta}
                    </h6>
                    <div>
                      <div className="block rounded-md border border-white">
                        <ArrowUpRight size={30} color="white" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default ResearchCarousel;
